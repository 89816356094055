import React, { useEffect, useMemo, useState } from "react";
import TableComponent from "../../components/table/Table.component";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Offcanvas,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import "./Store.style.scss";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete.component";

const Store = ({ objects }) => {
  const notify = () => toast("تمت العملية بنجاح");
  const [tableData, setTableData] = useState([]);
  const [objectsData, setObjectsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showSell, setShowSell] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [showTrans, setShowTrans] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReman, setShowReman] = useState(false);
  const [id, setId] = useState();
  const [fstCode, setFstCode] = useState("");
  const [seCode, setSeCode] = useState("");
  const [details, setDetails] = useState("");
  const [wit, setWit] = useState("");
  const [sId, setSid] = useState("");
  const [state, setState] = useState("");
  const [client, setClient] = useState("");
  const handleSellClose = () => setShowSell(false);
  const handleBackClose = () => setShowBack(false);
  const handleTransClose = () => setShowTrans(false);
  const handleEditClose = () => setShowEdit(false);
  const handleDeleteClose = () => setShowDelete(false);
  const handleRemanClose = () => setShowReman(false);

  const { register, handleSubmit } = useForm();

  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  const [date, setDate] = useState(defaultDate);
  var day = defaultDate.getDate();
  var month = defaultDate.getMonth() + 1;
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  let today = defaultDate.getFullYear() + "-" + month + "-" + day;

  const onSetDate = (event) => {
    setDate(new Date(event.target.value));
  };

  const handleBack = (row) => {
    console.log("to Back");
    setShowBack(true);
    setId(row.id);
    setClient(row.client);
  };
  const handleTransfere = (row) => {
    console.log("to trans");
    setShowTrans(true);
    setId(row.id);
    setSid(row.s_id);
    setState(row.state);
  };
  const handleEdit = (row) => {
    console.log("to edit");
    setShowEdit(true);
    setId(row.id);
    setDate(new Date(row.date));
    setFstCode(row.fstCode);
    setSeCode(row.seCode);
    setWit(row.wit);
    setDetails(row.details);
    setClient(row.client);
    console.log(row);
  };
  const handleDelete = (row) => {
    console.log("to delete");
    setShowDelete(true);
    setId(row.id);
  };
  const handleSell = (row) => {
    console.log("to sell");
    setShowSell(true);
    setId(row.id);
    setDate(defaultDate);
    setFstCode(row.fstCode);
    setSeCode(row.seCode);
    setWit(row.wit);
    setSid(row.s_id);
    setDetails(row.details);
    setState(row.state);
  };
  const handleSellItem = (d) => {
    d.id = id;
    d.state = state;
    d.s_id = sId;
    d.client = client
    console.log(d);
    if (client != "") {
      let formData = JSON.stringify(d);
      axios
        .post(`https://hallakjewelry.com/appApi/objects/sell.php?data=`, { formData })
        .then((res) => {
          console.log(res.data);
          if (res.data.message == "Inserted successfuly") {
            notify();
            setShowSell(false);
            setClient("")
            setFstCode("")
            fetchData(1);
          }
        });
    }
  };
  const handleBackItem = (d) => {
    d.id = id;
    d.client = client;
    d.date = today;
    let formData = JSON.stringify(d);
    axios
      .post(`https://hallakjewelry.com/appApi/objects/back.php?data=`, { formData })
      .then((res) => {
        if (res.data.message == "Returned successfuly") {
          notify();
          setShowBack(false);
          fetchData(1);
        }
      });
  };
  const handleEditItem = (d) => {
    d.id = id;
    let formData = JSON.stringify(d);
    axios
      .post(`https://hallakjewelry.com/appApi/objects/edit.php?data=`, { formData })
      .then((res) => {
        console.log(res.data);
        if (res.data.message == "Edited successfuly") {
          notify();
          setShowEdit(false);
          fetchData(1);
          setClient("")
        }
      });
  };
  const handleDeleteItem = () => {
    console.log(id);
    axios
      .post(`https://hallakjewelry.com/appApi/objects/delete.php?data=`, { id })
      .then((res) => {
        if (res.data.message == "Deleted successfuly") {
          notify();
          setShowDelete(false);
          fetchData(1);
          setClient("")

        }
      });
  };
  const handleTransItem = () => {
    const formData = {
      s_id: sId,
      id: id,
      state: state,
      date: today,
    };
    axios
      .post(`https://hallakjewelry.com/appApi/objects/move.php?data=`, { formData })
      .then((res) => {
        if (res.data.message == "Moved successfuly") {
          notify();
          setShowTrans(false);
          fetchData(1);
          setClient("")

        }
        console.log(res.data);
      });
  };
  const handleReManf = (row) => {
    console.log("Remanfacturing ...");
    setShowReman(true);
    setId(row.id);
  };
  const handleRemanItem = () => {
    console.log(id);
    axios
      .post(`https://hallakjewelry.com/appApi/objects/remanfacturing.php?data=`, {
        id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.message == "Remanfactured successfuly") {
          notify();
          setShowReman(false);
          fetchData(1);
          setClient("")

        }
      });
  };
  const getItem = (item) => {
    let city = "";
    if (item.s_id === "043") {
      city = "طرطوس";
    }
    if (item.s_id === "011") {
      city = "دمشق";
    }
    let status = "";
    switch (item.state) {
      case "1":
        status = "في المستودع";
        break;
      case "2":
        status = " مخرجة ";
        break;
      case "3":
        status = "مرتجعة ";
        break;
    }
    let data = [
      {
        id: item.id,
        fstCode: item.fstCode,
        details: item.details,
        seCode: item.seCode,
        wit: item.wit,
        client: item.client,
        state: item.state,
        status: status,
        city: city,
        s_id: item.s_id,
        date: item.date,
      },
    ];
    setTableData(data);
  };
  const getClient = (cli) => {
    console.log(cli);
    setClient(cli.name);
  };
  const objectColumns = useMemo(
    () => [
      {
        name: "رقم القطعة",
        selector: (row) => row.fstCode,
        sortable: true,
      },
      {
        name: "مواصفات الماس",
        selector: (row) => row.details,
        sortable: true,
      },
      {
        name: "وزن الماس",
        selector: (row) => row.seCode,
        sortable: true,
      },
      {
        name: "وزن ذهب",
        selector: (row) => row.wit,
        sortable: true,
      },
      {
        name: "تاريخ التصنيع",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "آخر تحديث",
        selector: (row) => row.newDate,
        sortable: true,
      },
      {
        name: "الزبون",
        selector: (row) => row.client,
        sortable: true,
      },
      {
        cell: (row) => {
          if (row.state == 2 || row.state == 4) {
            return (
              <DropdownButton id="dropdown-basic-button" title="">
                <Dropdown.Item>
                  <button
                    onClick={() => handleBack(row)}
                    className="btn btn-primary border w-100"
                  >
                    إرجاع
                  </button>
                </Dropdown.Item>
              </DropdownButton>
            );
          } else if (row.state == 3) {
            return (
              <DropdownButton id="dropdown-basic-button" title="">
                <Dropdown.Item>
                  <button
                    onClick={() => handleReManf(row)}
                    className="btn btn-primary border w-100"
                  >
                    إعادة تصنيع
                  </button>
                </Dropdown.Item>
              </DropdownButton>
            );
          } else {
            return (
              <DropdownButton  id="dropdown-variants-danger" variant="danger" title="">
                <Dropdown.Item>
                  <button
                    onClick={() => handleSell(row)}
                    className="btn btn-danger border w-100"
                  >
                    إخراج
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    onClick={() => handleTransfere(row)}
                    className="btn btn-info border white w-100"
                  >
                    ترحيل
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    onClick={() => handleEdit(row)}
                    className="btn btn-success border w-100"
                  >
                    تعديل
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    onClick={() => handleDelete(row)}
                    className="btn btn-dark border w-100"
                  >
                    حذف
                  </button>
                </Dropdown.Item>
              </DropdownButton>
              // <div className="row flex-row flex-nowrap">

              //   <button
              //     onClick={() => handleSell(row)}
              //     className="btn btn-danger border"
              //   >
              //     إخراج
              //   </button>
              //   <button
              //     onClick={() => handleTransfere(row)}
              //     className="btn btn-info border white"
              //   >
              //     ترحيل
              //   </button>
              //   <button
              //     onClick={() => handleEdit(row)}
              //     className="btn btn-success border"
              //   >
              //     تعديل
              //   </button>
              //   <button
              //     onClick={() => handleDelete(row)}
              //     className="btn btn-dark border"
              //   >
              //     حذف
              //   </button>
              // </div>
            );
          }
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        name: "A",
        selector: (row) => row.a,
        sortable: true,
      },
      {
        name: "B",
        selector: (row) => row.b,
        sortable: true,
      },
      {
        name: "E",
        selector: (row) => row.e,
        sortable: true,
      },
      {
        name: "N",
        selector: (row) => row.n,
        sortable: true,
      },
      {
        name: "M",
        selector: (row) => row.m,
        sortable: true,
      },
      {
        name: "R",
        selector: (row) => row.r,
        sortable: true,
      },
      {
        name: "وزن ذهب",
        selector: (row) => parseInt(row.witSum),
        sortable: true,
      },
      {
        name: "وزن الماس",
        selector: (row) => parseInt(row.seCodeSum),
        sortable: true,
      },
    ],
    []
  );
  const fetchData = async (page) => {
    setLoading(true);
    var str = document.getElementById("store").value;
    var type = document.getElementById("type").value;
    const response = await axios.get(
      `https://hallakjewelry.com/appApi/objects/fetch.php?str=${str}&status=${type}&page=${page}&per_page=${perPage}&delay=1`
    );
    console.log(response.data);
    setTableData(response.data.data);
    setObjectsData(response.data.typesCount);
    setTotalRows(response.data.total);

    setLoading(false);
  };

  const viewData = () => {
    fetchData(1);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    var str = document.getElementById("store").value;
    var type = document.getElementById("type").value;
    const response = await axios.get(
      `https://hallakjewelry.com/appApi/objects/fetch.php?str=${str}&status=${type}&page=${page}&per_page=${newPerPage}&delay=1`
    );

    setTableData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row text-center py-5">
          <h4 className="fw-bold"> المستودعات </h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <select
              name="store"
              id="store"
              className="form-control form-select"
            >
              <option value="011"> دمشق </option>
              <option value="043"> طرطوس </option>
            </select>
          </div>
          <div className="col-md-2">
            <select name="type" id="type" className="form-control form-select">
              <option value="all"> الكل </option>
              <option value="1"> في المستودع </option>
              <option value="2"> مخرجة </option>
              <option value="3"> مرتجعة </option>
            </select>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary border" onClick={viewData}>
              استعراض
            </button>
          </div>
        </div>
        <div className="row my-5 justify-content-center">
          <div className="col-md-10 border">
            <TableComponent
              tableData={objectsData}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <div className="row">
          <TableComponent
            tableData={tableData}
            columns={objectColumns}
            loading={loading}
            pagination={true}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
          />
        </div>

        <Modal show={showBack} onHide={handleBackClose} className="ltr">
          <Modal.Header>
            <Modal.Title> تأكيد العملية</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3> هل أنت متأكد أنك تريد إرجاع القطعة ؟ </h3>
            <form onSubmit={handleSubmit(handleBackItem)}>
              <div className="row justify-content-center  p-5 px-0 px-md-5 h-100">
                <div className="row justify-content-center align-items-center ">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="s_id" className="form-label fw-bold">
                        {" "}
                        مستودع الإرجاع
                      </label>
                      <select
                        className="form-select mt-4"
                        placeholder="المستودع "
                        aria-label=""
                        name="s_id"
                        id="s_id"
                        {...register("s_id")}
                      >
                        <option value="011"> دمشق </option>
                        <option value="043"> طرطوس </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end fex-wrap mt-5">
                    <button
                      className="btn btn-primary border white mx-3 "
                      type="submit"
                    >
                      إرجاع
                    </button>
                    <Button variant="secondary" onClick={handleBackClose}>
                      تراجع
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={showTrans} onHide={handleTransClose} className="ltr">
          <Modal.Header>
            <Modal.Title> تأكيد العملية</Modal.Title>
          </Modal.Header>
          <Modal.Body>هل أنت متأكد أنك تريد ترحيل القطعة ؟</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleTransItem()}>
              ترحيل
            </Button>
            <Button variant="secondary" onClick={handleTransClose}>
              تراجع
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleDeleteClose} className="ltr">
          <Modal.Header>
            <Modal.Title> تأكيد العملية</Modal.Title>
          </Modal.Header>
          <Modal.Body>هل أنت متأكد أنك تريد حذف القطعة ؟</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleDeleteItem()}>
              حذف
            </Button>
            <Button variant="secondary" onClick={handleDeleteClose}>
              تراجع
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showReman} onHide={handleRemanClose} className="ltr">
          <Modal.Header>
            <Modal.Title> تأكيد العملية</Modal.Title>
          </Modal.Header>
          <Modal.Body>هل أنت متأكد أنك تريد إعادة تصنيع القطعة ؟</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleRemanItem()}>
              إعادة تصنيع
            </Button>
            <Button variant="secondary" onClick={handleRemanClose}>
              تراجع
            </Button>
          </Modal.Footer>
        </Modal>

        <Offcanvas
          show={showSell}
          onHide={handleSellClose}
          placement="top"
          className="h-75"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="text-center w-100">
              {" "}
              إخراج من المستودع{" "}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="py-0 bg-primary">
            <form onSubmit={handleSubmit(handleSellItem)}>
              <div className="row justify-content-center  p-5 px-0 px-md-5 h-100">
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-4">
                      <AutocompleteComponent
                        getItem={(item) => getClient(item)}
                        file="searchClients"
                        itm="name"
                        {...register("client")}
                        id="client"                        
                        defaultValue = {client? client : ""}
                        placeholder = "الزبون"
                        newVal = {client ? client: ""}
                      />
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text"> القطعة</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" القطعة "
                        aria-label=""
                        name="fstCode"
                        id="fstCode"
                        defaultValue={fstCode}
                        {...register("fstCode")}
                        // onChange={(e) => setFstCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text">وزن ألماس</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" وزن ألماس"
                        aria-label=""
                        name="seCode"
                        id="seCode"
                        defaultValue={seCode}
                        {...register("seCode")}
                        // onChange={(e) => setSeCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text">وزن ذهب</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" وزن ذهب"
                        aria-label=""
                        name="wit"
                        id="wit"
                        defaultValue={wit}
                        {...register("wit")}
                        // onChange={(e) => setWit(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-8">
                    <div className="input-group">
                      <span className="input-group-text"> التفاصيل</span>
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                        name="details"
                        id="details"
                        defaultValue={details}
                        {...register("details")}

                        // onChange={(e) => setDetails(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-4 mt-4">
                    <span className="input-group-text"> التاريخ</span>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date"
                      placeholder="التاريخ"
                      defaultValue={date.toLocaleDateString("en-CA")}
                      // onChange={onSetDate}
                      {...register("date")}
                    />
                  </div>
                  <div className="col-md-4 d-flex justify-content-end fex-wrap mt-5">
                    <button className="btn border white mx-3 " type="submit">
                      إخراج{" "}
                    </button>
                    <button
                      className="btn border white mx-3"
                      type="reset"
                      onClick={handleSellClose}
                    >
                      {" "}
                      إلغاء{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={showEdit}
          onHide={handleEditClose}
          placement="top"
          className="h-75"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="text-center w-100">
              تعديل قطعة
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="py-0 bg-primary">
            <form onSubmit={handleSubmit(handleEditItem)}>
              <div className="row justify-content-center  p-5 px-0 px-md-5 h-100">
                <div className="row justify-content-center align-items-center">
                <div className="col-md-4">
                      <AutocompleteComponent
                        getItem={(item) => getClient(item)}
                        file="searchClients"
                        itm="name"
                        {...register("client")}
                        id="client"
                        defaultValue = {client}
                        placeholder = "الزبون"
                      />
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text">القطعة</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" القطعة "
                        aria-label=""
                        name="fstCode"
                        id="fstCode"
                        defaultValue={fstCode}
                        // onChange={(e) => setFstCode(e.target.value)}
                        {...register("fstCode")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text">وزن ألماس</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" وزن ألماس"
                        aria-label=""
                        name="seCode"
                        id="seCode"
                        defaultValue={seCode}
                        // onChange={(e) => setSeCode(e.target.value)}
                        {...register("seCode")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text"> وزن ذهب</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" وزن ذهب"
                        aria-label=""
                        name="wit"
                        id="wit"
                        defaultValue={wit}
                        // onChange={(e) => setWit(e.target.value)}
                        {...register("wit")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-8">
                    <div className="input-group">
                      <span className="input-group-text"> التفاصيل</span>
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                        name="details"
                        id="details"
                        defaultValue={details}
                        onChange={(e) => setDetails(e.target.value)}
                        {...register("details")}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-4 mt-4">
                    <span className="input-group-text"> التاريخ</span>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date"
                      placeholder="التاريخ"
                      value={date.toLocaleDateString("en-CA")}
                      onChange={onSetDate}
                      {...register("date")}
                    />
                  </div>
                  <div className="col-md-4 d-flex justify-content-end fex-wrap mt-5">
                    <button className="btn border white mx-3 " type="submit">
                      {" "}
                      تعديل{" "}
                    </button>
                    <button
                      className="btn border white mx-3"
                      type="reset"
                      onClick={handleEditClose}
                    >
                      {" "}
                      إلغاء{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  objects: state.objects.objects,
});
export default connect(mapStateToProps)(Store);
