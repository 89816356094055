const INITIAL_STATE = {
  currentPage:0,
  lastPage: null,
  objects: [],
};

const objectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_OBJECTS":
      return {
        ...state,
        objects: action.payload
      };
    default:
      return state;
  }
};

export default objectReducer;
