import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import objectReducer from "./objects/object.reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'user',
        'objects'
    ]
}
const rootReducer = combineReducers({
    user: userReducer,
    objects: objectReducer
  });
export default  persistReducer(persistConfig , rootReducer)
