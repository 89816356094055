import React from 'react'

const Input = (props) => {
  return (
    <>
        <input type={props.type}
         name={props.name} 
        id={props.id}
         className={props.classes}
         value={props.value}
         onClick= {props.click}
         onChange= {props.change}
         placeholder={props.placeholder} />
    </>
  )
}

export default Input