import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./Autocomplete.style.scss";

const AutocompleteComponent = (props) => {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const lastRequest = useRef(null);

  // this effect will be fired every time searchText changes
  useEffect(() => {
    // setting min lenght for searchText
    if (searchText.length >= 1) {
      // updating the ref variable with the current searchText
      lastRequest.current = searchText;
      axios
        .post(
          `https://hallakjewelry.com/appApi/objects/${props.file}.php?string=${searchText}`,
          { searchText }
        )
        .then((r) => {
          console.log(r.data);
          // the code in here is asyncronous so searchText
          // that was used when calling the api might be outdated
          // that's why we compare it to the vaue contained in the ref,
          // because the ref is never outdated (it's mutable)
          if (lastRequest.current === searchText) {
            setResults(r.data);
          } else {
            console.log(
              "discarding api response",
              searchText,
              lastRequest.current
            );
          }
        })
        .catch((e) => console.log(e));
    } else {
      setResults([]);
    }
  }, [searchText]);
  const hideList = (item) => {
    document.getElementById("searchInput").value = item.fstCode;
    setResults([]);
  };
  return (
    <>
      <div className="autocomplete">
        <div className="input-group mb-3">
          <span className="input-group-text">{props.placeholder}</span>

          <input
            className="form-control"
            id="searchInput"
            onChange={(e) => setSearchText(e.target.value)}
            value={props.value ? props.value : props.newVal? props.newVal : searchText }
            // placeholder={props.placeholder}
            autoComplete="off"
          />
          <ul className="list-unstyled" id="results">
            {results.length > 0
              ? results.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      props.getItem(item);
                      hideList(item);
                    }}
                  >
                    {item.fstCode ? item.fstCode : item.name}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AutocompleteComponent;
