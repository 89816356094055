import React, { useCallback, useEffect, useMemo, useState } from "react";

import DataTable from "react-data-table-component";

function TableComponent(props) {
  const paginationComponentOptions = {
    rowsPerPageText: "عدد الأسطر في الصفحة",
    rangeSeparatorText: "إلى",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };

  return (
    <DataTable
      title=""
      data={props.tableData}
      columns={props.columns}
      progressPending={props.loading}
			pagination = {props.pagination}
			paginationServer
      
			paginationTotalRows={props.totalRows}
			onChangeRowsPerPage={props.handlePerRowsChange}
			onChangePage={props.handlePageChange}
      paginationComponentOptions={paginationComponentOptions}
    />
  );
}

export default TableComponent;
