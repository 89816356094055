import axios from "axios";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete.component";
import TableComponent from "../../components/table/Table.component";

const Details = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState("");

  const { register, handleSubmit } = useForm();

  const getClient = (cli) => {
    const formData = { client: cli.id,}
    axios
        .post(`https://hallakjewelry.com/appApi/objects/details.php?data=`, { formData })
        .then((res) => {
              setTableData(res.data);
         
        });
  };

  const objectColumns = useMemo(
    () => [
      {
        name: "رقم القطعة",
        selector: (row) => row.fstCode,
        sortable: true,
        className: "fw-bold",
      },
      {
        name: "التاريخ ",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "المستودع ",
        selector: (row) => row.city,
        sortable: true,
      },
    ],
    []
  );
  return (
    <div className="container-fluid search">
      <div className="row text-center py-5">
        <h4 className="fw-bold">تفاصيل زبون </h4>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-12 bg-primary py-5 d-flex justify-content-center form-container">
          <AutocompleteComponent
            getItem={(item) => getClient(item)}
            file="searchClients"
            itm="name"
            id="client"
            placeholder="الزبون"
          />
        </div>
      </div>
      <div className="row my-4 align-items-center justify-content-center">
        <div className="col-md-10 col-sm-12 p-5">
          <TableComponent
            tableData={tableData}
            columns={objectColumns}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
