import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Manufacturing = () => {
  const notify = () => toast("تمت العملية بنجاح");

  const { register, handleSubmit } = useForm();
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const [date, setDate] = useState(defaultDate);

  const onSetDate = (event) => {
    setDate(new Date(event.target.value));
  };

  const saveData = (d) => {
    let formData = JSON.stringify(d);
    axios
      .post(`https://hallakjewelry.com/appApi/objects/insert.php?data=`, { formData })
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          notify();
          document.getElementById("reset_btn").click();
        }
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row text-center py-5">
          <h4 className="fw-bold">تصنيع </h4>
        </div>
        <form onSubmit={handleSubmit(saveData)}>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10 col-sm-12 bg-primary py-5 d-flex flex-wrap justify-content-around mb-5">
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <span className="input-group-text">@</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="وزن ذهب"
                    aria-label=""
                    name="wit"
                    id="wit"
                    {...register("wit")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <span className="input-group-text">@</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="وزن الماس"
                    aria-label=""
                    name="seCode"
                    id="seCode"
                    {...register("seCode")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <span className="input-group-text">@</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="رقم المادة "
                    aria-label=""
                    name="fstCode"
                    id="fstCode"
                    {...register("fstCode")}
                  />
                </div>
              </div>
              <div className="col-9">
                <div className="input-group">
                  <span className="input-group-text"> التفاصيل</span>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    name="details"
                    id="details"
                    {...register("details")}
                  ></textarea>
                </div>
              </div>
              <div className="col-4 mt-4">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  id="date"
                  placeholder="التاريخ"
                  value={date.toLocaleDateString("en-CA")}
                  onChange={onSetDate}
                  {...register("date")}
                />
              </div>
              <div className="col-12 d-flex justify-content-center fex-wrap mt-5">
                <button className="btn border white mx-3 " type="submit">
                  تصنيع
                </button>
                <button
                  className="btn border white mx-3"
                  type="reset"
                  id="reset_btn"
                >
                  إلغاء
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Manufacturing;
