import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { connect } from "react-redux";


const PrivateRoute = (user) => {

  return user.user?<Outlet />: <Navigate to="/app/login" />
}
const mapStateToProps = (state) => ({
    user: state.user.userName,
  });


export default connect(mapStateToProps)(PrivateRoute)