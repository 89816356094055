import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const User = () => {
  const notify = () => toast("كلمة المرور غير مطابقة يرجى التأكد من جديد");
  const notifySuccess = () => toast("تمت العملية بنجاح");
  const [show, setShow] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [val, setVal] = useState(false);
  const [code, setCode] = useState(Math.floor(100000 + Math.random() * 900000));
  const saveData = (d) => {
    if (checkMatch) {
      let formData = JSON.stringify(d);
      axios
        .post(`https://hallakjewelry.com/appApi/users/insert.php?data=`, { formData })
        .then((res) => {
          if (res.data.error) {
            alert(res.data.error);
          } else {
            notifySuccess();
            document.getElementById("reset_btn").click();
          }
        });
    }
    else {
      notify()
    }
  };
  const checkMatch = () => {
    let pass = document.getElementById("h_pas").value;
    let re_pass = document.getElementById("h_rePas").value;
    if (re_pass != pass) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row text-center py-5">
          <h4 className="fw-bold">مستخدم جديد </h4>
        </div>

        <form onSubmit={handleSubmit(saveData)}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-12 bg-primary py-5 d-flex flex-wrap justify-content-around mb-5">
              <div className="row w-100 justify-content-center">
                <div className="col-md-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">@</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="الاسم "
                      aria-label=""
                      name="hf_name"
                      id="hf_name"
                      {...register("hf_name")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">@</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="الكنية "
                      aria-label=""
                      name="hl_name"
                      id="hl_name"
                      {...register("hl_name")}
                    />
                  </div>
                </div>
              </div>
              <div className="row  w-100 justify-content-center">
                <div className="col-md-8">
                  <div className="input-group mb-3">
                    <span className="input-group-text">@</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="اسم المستخدم   "
                      aria-label=""
                      name="h_user"
                      id="h_user"
                      {...register("h_user")}
                    />
                  </div>
                </div>
              </div>
              <div className="row w-100 justify-content-center">
                <div className="col-md-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">@</span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="كلمة السر "
                      aria-label=""
                      name="h_pas"
                      id="h_pas"
                      {...register("h_pas")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">@</span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="إعادة كلمة السر "
                      aria-label=""
                      name="h_rePas"
                      id="h_rePas"
                    />
                  </div>
                </div>
              </div>
              <div className="row  w-100 justify-content-center">
                <div className="col-md-8">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      aria-label=""
                      name="h_code"
                      id="h_code"
                      {...register("h_code")}
                      defaultValue={code}
                    />
                  </div>
                </div>
              </div>
              <div className="row  w-100 justify-content-center">
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    Admin{" "}
                    <input
                      type="checkbox"
                      name="role"
                      id="role"
                      {...register("role")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center fex-wrap mt-5">
                <button className="btn border white mx-3 ">
                  {" "}
                  تسجيل{" "}
                </button>
                <button
                  className="btn border white mx-3"
                  type="reset"
                  id="reset_btn"
                >
                  {" "}
                  إلغاء{" "}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default User;
