import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Menu from "./components/Menu/Menu.component";
import Login from "./pages/Login/Login.page";
import Manufacturing from "./pages/Manufacturing/Manufacturing.page";
import Search from "./pages/Search/Search.page";
import Store from "./pages/Store/Store.page";
import User from "./pages/User/User.page";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Details from "./pages/Details/Details.page";

const App = (user) => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/app/login" ? null : <Menu />}
      <Routes>
        <Route path="/app/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/app/" element={<Search />} />
          <Route path="/app/search" element={<Search />} />
          <Route path="/app/store" element={<Store />} />
          <Route path="/app/export" element={<Search />} />
          <Route path="/app/manufacturing" element={<Manufacturing />} />
          <Route path="/app/users" element={<User />} />
          <Route path="/app/details" element={<Details />} />
        </Route>
      </Routes>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.userName,
});

export default connect(mapStateToProps)(App);
