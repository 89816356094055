import React, { useState } from "react";
import Input from "../../components/Form/Input.component";
import logo from "../../assets/img/logo.png";
import "./Login.style.scss";
import { connect } from "react-redux";
import axios from "axios";
import { setCurrentUser } from "../../redux/user/user.action";
import { useNavigate } from "react-router-dom";
import { getAllObjects } from "../../redux/objects/object.action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = ({ user, setCurrentUser, getAllObjects }) => {
  const notify = () => {
    toast.error("خطأ في كلمة المرور", {
      position: toast.POSITION.TOP_RIGHT
    });
  };
  const navigate = useNavigate();
  const [userName, setUserName] = useState(user);
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    if (userName == "") {
      alert("you must enter username");
      document.getElementById("userName").focus();
      return;
    }
    if (password == "") {
      alert("you must enter password");
      document.getElementById("password").focus();
      return;
    }
    const formData = {
      userName: userName,
      password: password,
    };
    axios
      .post(`https://hallakjewelry.com/appApi/users/user.php?data=`, { formData })
      .then((res) => {
        if (res.data.error) {
          if( res.data.error === 'uncorrect password'){
            notify()
          }
        } else {
          setCurrentUser(res.data.userName);
          axios.get('https://hallakjewelry.com/appApi/objects/page.php').then((response) => {
            console.log(typeof(response.data))
              getAllObjects(response.data);
          })
          navigate("/app/search", { replace: true });
        }
      });
  };
  return (
    <>
    <ToastContainer />
      <div className="container-fluid login">
        <div className="row">
          <div className="col-md-6 col-sm-12 right-side">
            <img src={logo} className="img logo" />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="userName"> اسم المستخدم </label>
                <Input
                  classes="form-control"
                  type="text"
                  name="userName"
                  id="userName"
                  change={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password"> كلمة المرور </label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  classes="form-control"
                  change={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <Input
                  type="submit"
                  classes="btn btn-primary w-50 mt-5 align-self-center"
                  value="تسجيل دخول"
                />
              </div>
            </form>
          </div>
          <div className="col-6 d-md-block d-none left-side"></div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.userName,
  objects: state.objects
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  getAllObjects: (objects) => dispatch(getAllObjects(objects))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
