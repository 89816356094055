import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import './Menu.style.scss';

import { connect } from 'react-redux';
import { setCurrentUser } from "../../redux/user/user.action";
import axios from "axios";

const Menu = ( { setCurrentUser }) => {
  let defaultDate = new Date();

  var day = defaultDate.getDate();
  var month = defaultDate.getMonth() + 1;
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  let today = defaultDate.getFullYear() + "-" + month + "-" + day;
  const navigate = useNavigate();
  const handleLogout = () => {
    setCurrentUser(null);
    localStorage.removeItem("persist:root")
    navigate('./app/login', { replace: true });
  }
  const handleBackUp = () => {
    axios
    .get(`https://hallakjewelry.com/appApi/backup.php` , {responseType: 'blob'} )
    .then((res) => {
      const url = window.URL.createObjectURL( new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','hallakJewelry_backup_'+today+'.sql.gz');
      document.body.appendChild(link);
      link.click();
    
    });
  }
  return (
    <>
    <div className="container-fluid bg-primary">
      <div className="row pt-5 justify-content-center menu">
        <div className="col-12 text-center mb-5">
          <h1 className="white"> HALLAK JEWELRY APP</h1>
        </div>
        <div className="col-md-8 d-flex justify-content-center">
          <Navbar expand="lg">
            <Container fluid className="justify-content-between">
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse
                id="navbarScroll"
                className="justify-content-between"
              >
                <Nav>
                  <Nav.Link as={Link} to="/app/search" className="ms-3 px-2">
                    بحث
                  </Nav.Link>
                  <Nav.Link as={Link} to="/app/manufacturing" className="ms-3 px-2">
                    تصنيع
                  </Nav.Link>
                  <Nav.Link as={Link} to="/app/store" className="ms-3 px-2">
                    مستودع
                  </Nav.Link>
                  <Nav.Link as={Link} to="/app/users" className="ms-3 px-2">
                    مستخدمون
                  </Nav.Link>
                  <Nav.Link as={Link} to="/app/details" className="ms-3 px-2">
                    تفاصيل زبون 
                  </Nav.Link>
                  <Nav.Link  onClick={ ()=> handleBackUp() } className="ms-3 px-2">
                    نسخ احتياطي
                  </Nav.Link>
                  <Nav.Link onClick={ handleLogout} className="ms-3 px-2">
                     تسجيل خروج
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
})
export default connect(null, mapDispatchToProps)(Menu);
